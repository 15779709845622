"use client";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const portfolio = [
  {
    id: 1,
    name: "UKCTM.CO.UK",
    href: "https://ukctm.co.uk/",
    imageSrc: "/images/portfolio/ukctm.png",
    imageAlt: "UI/UX DESIGN, WEB DEVELOPMENT REACTJS FRONT-END & BACK END",
  },
  {
    id: 2,
    name: "SATASOFTWARE.COM",
    href: "https://satasoftware.com/",
    imageSrc: "/images/portfolio/sata.png",
    imageAlt:
      "UI/UX Design, Web Development ASP.NET Entity Front-End & Back End",
  },
  {
    id: 3,
    name: "Develand Social Media",
    href: "https://social.enessahin.dev/",
    imageSrc: "/images/portfolio/social-media-app.png",
    imageAlt: "UI/UX DESIGN, WEB DEVELOPMENT FRONT-END & BACK-END",
  },
  {
    id: 4,
    name: "Rent A Car Web Project with React",
    href: "https://rent-a-car-dusky.vercel.app/",
    imageSrc: "/images/portfolio/budemicar.png",
    imageAlt: "UI/UX DESIGN, WEB DEVELOPMENT FRONT-END",
  },
  {
    id: 5,
    name: "OIP Marketing Project with React",
    href: "https://oipmarketing.netlify.app/",
    imageSrc: "/images/portfolio/oipv1.png",
    imageAlt: "UI/UX DESIGN, WEB DEVELOPMENT FRONT-END",
  },
  {
    id: 6,
    name: "PetWash Project with React",
    href: "https://petwash.netlify.app/",
    imageSrc: "/images/portfolio/petwash.png",
    imageAlt: "UI/UX DESIGN, WEB DEVELOPMENT FRONT-END & BACK-END",
  },
  {
    id: 7,
    name: "Real Estate Project with React",
    href: "https://react-nextjs-real-estate-project.vercel.app/",
    imageSrc: "/images/portfolio/real-estate-project.png",
    imageAlt: "UI/UX DESIGN, WEB DEVELOPMENT FRONT-END & BACK-END",
  },
  {
    id: 8,
    name: "Beachaway Project with React",
    href: "https://beachaways-react-exercise.vercel.app/",
    imageSrc: "/images/portfolio/beachaway-project.png",
    imageAlt: "UI/UX DESIGN, WEB DEVELOPMENT FRONT-END",
  },
  {
    id: 9,
    name: "Tatto's Studio Web Page Project with React",
    href: "https://tattoo-studio-reactjs.netlify.app/",
    imageSrc: "/images/portfolio/tattos.png",
    imageAlt: "UI/UX DESIGN, WEB DEVELOPMENT FRONT-END",
  },
  {
    id: 10,
    name: "ChatGPT-4 Summery AI Project with React",
    href: "https://react-ai-summarizer.vercel.app/",
    imageSrc: "/images/portfolio/summenzy.png",
    imageAlt: "UI/UX DESIGN, WEB DEVELOPMENT FRONT-END & BACK-END",
  },
  {
    id: 11,
    name: "ItemPay Rise Online MMORPG Calculate Project",
    href: "https://item-pay.vercel.app/",
    imageSrc: "/images/portfolio/itempay.png",
    imageAlt: "UI/UX DESIGN, WEB DEVELOPMENT FRONT-END",
  },
];

export default function Portfolio() {
  return (
    <section className="overflow-hidden bg-white py-24 sm:py-32" id="portfolio">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold leading-7 text-indigo-600">
          PORTFOLIO
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          My Portfolio
        </p>

        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {portfolio.map((product) => (
            <div key={product.id}>
              <div>
                <a href={product.href}>
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8"
                  />
                </a>
              </div>
              <div className="mt-4 flex justify-center">
                <div>
                  <motion.h1
                    variants={fadeIn("down", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.6 }}
                    className="text-md font-bold text-center justify-center text-primary hover:text-legendary-default"
                  >
                    <a href={product.href}>
                      <span aria-hidden="true" />
                      {product.name}
                    </a>
                  </motion.h1>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
